<template>
    <!-- Vuetify의 v-app 컴포넌트로 앱의 기본 레이아웃을 정의합니다. -->
    <v-app>
        <!-- router-view를 사용하여 현재 활성화된 라우트 컴포넌트를 렌더링합니다. -->
        <router-view></router-view>

        <!-- alert-component를 사용하여 알림을 표시합니다. -->
        <!-- Vuex의 상태를 바인딩하여 메시지와 콜백 함수, 표시 여부를 설정합니다. -->


        <!-- v-overlay를 사용하여 로딩 오버레이를 표시합니다. -->
        <!-- Vuex의 상태를 바인딩하여 오버레이의 z-index와 표시 여부를 설정합니다. -->
        <v-overlay :z-index="$store.state.app.loading.zIndex" :value="$store.state.app.loading.overlay">
            <!-- v-progress-circular를 사용하여 무한 진행률 로딩 아이콘을 표시합니다. -->
            <v-progress-circular indeterminate size="128"></v-progress-circular>
        </v-overlay>
    </v-app>
</template>

<script lang="ts">
    // Vue 인스턴스를 가져옵니다.
    import Vue from 'vue';

    // 새로운 Vue 인스턴스를 생성합니다.
    const app = new Vue({});

    // Vue 인스턴스를 기본 내보내기로 내보냅니다.
    export default app;
</script>

<style scoped>
/* 이 컴포넌트에 대한 스타일을 정의합니다. */
/* scoped 속성을 사용하여 이 컴포넌트 내에서만 적용되도록 설정합니다. */
</style>
