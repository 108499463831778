// Vue와 Vuex를 불러옵니다.
import Vue from 'vue';
import Vuex from 'vuex';

// Vue에 Vuex 플러그인을 추가합니다.
Vue.use(Vuex);

// Vuex 스토어를 생성하여 내보냅니다.
export default new Vuex.Store({
    // 모듈을 설정하여 상태 관리의 범위를 분리합니다.
    modules: {
        // 'app' 모듈 정의
        app: {
            // 네임스페이스를 활성화하여 모듈의 범위를 구분합니다.
            namespaced: true,

            // state: 애플리케이션의 상태를 저장합니다.
            state() {
                return {
                    token: null, // 인증 토큰
                    user: null, // 사용자 정보
                    codeList: [] as any, // 코드 리스트
                    menuList: null, // 메뉴 리스트
                    roleList: null, // 역할 리스트
                    treeMenuList: null, // 트리 메뉴 리스트
                    showMenuPathName: null, // 현재 보여지는 메뉴 경로 이름
                    topMenuCode: null, // 최상위 메뉴 코드
                    individuals: null, // 개인 정보
                    language: null, // 언어 설정
                    alert: { // 알림 관련 상태
                        value: false, // 알림 표시 여부
                        message: '', // 알림 메시지
                        callback() {}, // 알림 콜백 함수
                    },
                    confirm: { // 확인 창 관련 상태
                        value: false, // 확인 창 표시 여부
                        message: '', // 확인 메시지
                        oktext: '확인', // 확인 버튼 텍스트
                        canceltext: '취소', // 취소 버튼 텍스트
                        callback() {}, // 확인 창 콜백 함수
                    },
                    loading: { // 로딩 오버레이 상태
                        overlay: false, // 로딩 오버레이 표시 여부
                    },
                };
            },

            // getters: 상태를 반환하는 함수들입니다.
            getters: {
                // 인증 토큰을 반환합니다.
                GET_TOKEN: (state) => state.token,

                // 사용자 정보를 반환합니다.
                GET_USER: (state) => state.user,

                // 코드 리스트를 반환합니다.
                GET_CODE_LIST: (state) => state.codeList,

                // 메뉴 리스트를 반환합니다.
                GET_MENU_LIST: (state) => state.menuList,

                // 트리 메뉴 리스트를 반환합니다.
                GET_TREE_MENU_LIST: (state) => state.treeMenuList,

                // 역할 리스트를 반환합니다.
                GET_ROLE_LIST: (state) => state.roleList,

                // 알림 상태를 반환합니다.
                GET_ALERT: (state) => state.alert,

                // 확인 창 상태를 반환합니다.
                GET_CONFIRM: (state) => state.confirm,

                // 현재 보여지는 메뉴 경로 이름을 반환합니다.
                GET_SHOW_MENU_PATH_NAME: (state) => state.showMenuPathName,

                // 개인 정보를 반환합니다.
                SET_TOP_INDIVIDUALS: (state) => state.individuals,

                // 언어 설정을 반환합니다.
                GET_TOP_LANGUAGE: (state) => state.language,
            },

            // mutations: 상태를 직접 변경하는 함수들입니다.
            mutations: {
                // 인증 토큰을 설정합니다.
                SET_TOKEN(state, payload) {
                    state.token = payload;
                },

                // 사용자 정보를 설정합니다.
                SET_USER(state, payload) {
                    state.user = payload;
                },

                // 코드 리스트를 설정합니다.
                SET_CODE_LIST(state, payload) {
                    state.codeList = payload;
                },

                // 메뉴 리스트를 설정합니다.
                SET_MENU_LIST(state, payload) {
                    state.menuList = payload;
                },

                // 트리 메뉴 리스트를 설정합니다.
                SET_TREE_MENU_LIST(state, payload) {
                    state.treeMenuList = payload;
                },

                // 역할 리스트를 설정합니다.
                SET_ROLE_LIST(state, payload) {
                    state.roleList = payload;
                },

                // 알림 상태를 설정합니다.
                SET_ALERT(state, payload) {
                    state.alert = payload;
                },

                // 현재 보여지는 메뉴 경로 이름을 설정합니다.
                SET_SHOW_MENU_PATH_NAME(state, payload) {
                    state.showMenuPathName = payload;
                },

                // 확인 창 상태를 설정합니다.
                SET_CONFIRM(state, payload) {
                    state.confirm = payload;
                },

                // 로딩 오버레이 상태를 설정합니다.
                SET_LOADING(state, payload) {
                    state.loading.overlay = payload;
                },

                // 개인 정보를 설정합니다.
                SET_TOP_INDIVIDUALS(state, payload) {
                    state.individuals = payload;
                },

                // 언어 설정을 설정합니다.
                SET_TOP_LANGUAGE(state, payload) {
                    state.language = payload;
                },
            },

            // actions: 비동기 작업 및 여러 뮤테이션을 포함하는 작업들을 정의합니다.
            actions: {
                // 현재 액션 정의는 비어 있습니다.
            },
        },
    },
});
