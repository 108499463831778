// Vue 프레임워크를 불러옵니다.
import Vue from 'vue';

// HTTP 요청을 처리하기 위한 Axios 라이브러리를 불러옵니다.
import axios from 'axios';

// Vue Router 설정을 불러옵니다.
import router from '@/config/vue-router';

// 쿼리 문자열을 직렬화하기 위한 Qs 라이브러리를 불러옵니다.
import Qs from 'qs';

// JWT 토큰을 디코딩하기 위한 jwt-decode 라이브러리를 불러옵니다.
import jwtDecode, { JwtPayload } from 'jwt-decode';

// Vuex 스토어를 불러옵니다.
import store from '@/config/store';

// Basil은 로컬 및 세션 스토리지를 다루기 위한 라이브러리입니다.
import * as Basil from '@/plugins/basil.min';

// meta 객체는 애플리케이션에서 전역적으로 사용되는 다양한 유틸리티와 설정을 제공합니다.
const meta = {
    // 애플리케이션 버전 정보를 정의합니다.
    version: '1.0.0',

    // 차트 색상 배열을 정의합니다.
    chart: {
        colorArr: ['#FF754B', '#FEC90E', '#2E9875', '#B8DEC3', '#3E7AD3', '#4BCEFF', '#BFB8DE', '#6F3ED3', '#483ED3'],
    },

    // 초기화 메서드는 Axios 설정과 인증 토큰 설정을 담당합니다.
    async init() {
        // Axios의 기본 쿼리 문자열 직렬화 방법을 Qs 라이브러리를 사용하도록 설정합니다.
        axios.defaults.paramsSerializer = function paramsSerializer(params) {
            return Qs.stringify(params, { indices: false });
        };

        // 주석 처리된 부분은 Axios 응답에 대한 인터셉터 설정으로, 인증 실패 시 로그아웃 처리 로직을 포함합니다.
        /*
        axios.interceptors.response.use((response) => response, (error):any => {
            const { message, status } = error.response.data;
            switch (status) {
            case 403:
                (async () => {
                    const myToken = meta.auth.getToken();
                    if (myToken) {
                        await meta.auth.logout(myToken);
                    }
                    router.go(0);
                })();
                break;
            default:
                return error;
            }
            return error;
        });
        */

        // 인증 토큰을 가져옵니다.
        const token : String = meta.auth.getToken();

        // 토큰이 유효하면 Axios 기본 헤더에 Authorization 토큰을 설정합니다.
        if (token !== undefined && token !== null && token !== '') {
            axios.defaults.headers.Authorization = `Bearer ${token}`;
        }
    },

    // 사용자에게 메시지를 알림으로 표시하는 메서드입니다.
    alert(message: string) {
        return new Promise<void>((resolve) => {
            store.commit('app/SET_ALERT', {
                value: true,
                message,
                callback() {
                    resolve();
                },
            });
        });
    },

    // 사용자에게 확인 창을 표시하고 결과를 반환하는 메서드입니다.
    confirm(message: string, oktext = '예', canceltext = '아니오') {
        return new Promise((resolve) => {
            store.commit('app/SET_CONFIRM', {
                value: true,
                message,
                oktext,
                canceltext,
                callback(result: object) {
                    resolve(result);
                },
            });
        });
    },

    // 인증 관련 메서드를 포함하는 객체입니다.
    auth: {
        // 로그인 처리 메서드입니다.
        async login(username: string, password: string) {
            let header;
            let token;

            // 로컬 스토리지에서 기존의 토큰을 가져옵니다.
            token = Basil.localStorage.get('token');

            // 토큰이 존재하고 유효한지 확인합니다.
            if (token && await meta.auth.authenticated(token)) {
                header = `Bearer ${token}`;
            } else {
                // 서버에 로그인 요청을 보내고 응답 헤더에서 새로운 토큰을 가져옵니다.
                header = (await axios({
                    url: '/api/login',
                    method: 'post',
                    data: {
                        username,
                        password,
                    },
                })).headers;

                // 새로운 토큰을 저장하고, 쿠키에 리프레시 토큰을 설정합니다.
                token = header.authorization.replace('Bearer ', '');
                Vue.$cookies.set('refresh_token', header.refreshtoken, '14d');
                Basil.localStorage.set('token', token);
            }

            // Axios의 기본 헤더에 인증 토큰을 설정합니다.
            axios.defaults.headers.Authorization = header.authorization;
        },

        // 로그아웃 처리 메서드입니다.
        async logout(token: string) {
            // 서버에 로그아웃 요청을 보냅니다.
            await axios({
                url: '/api/logout',
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // 리프레시 토큰과 인증 토큰을 제거합니다.
            Vue.$cookies.remove('refresh_token');
            Basil.localStorage.remove('token');
            delete axios.defaults.headers.Authorization;
        },

        // 회원가입 처리 메서드입니다.
        async signUp(data: object) {
            return axios({
                url: '/api/app/accounts/sign-up',
                method: 'post',
                data,
            });
        },

        // 아이디 존재 여부를 확인하는 메서드입니다.
        async idExists(data: object) {
            return axios({
                url: '/api/common/users/id-exists',
                method: 'post',
                data,
            });
        },

        // 토큰이 유효한지 확인하는 메서드입니다.
        async authenticated(token: string) {
            if (token) {
                try {
                    // 서버에 토큰 유효성을 검사합니다.
                    await axios({
                        url: '/api',
                        method: 'get',
                        headers: {
                            Authorization: token,
                        },
                    });
                    return true;
                } catch (e) {
                    return false;
                }
            } else {
                return false;
            }
        },

        // 사용자 권한을 설정하는 메서드입니다.
        async authorize(token: string) {
            let authentication = {
                user: Object,
                roleList: Object,
                menuList: Object,
                treeMenuList: Object,
            } as any;

            // JWT 토큰을 디코딩하여 사용자 정보를 가져옵니다.
            authentication = jwtDecode<JwtPayload>(token);

            // Vuex 스토어에 사용자 정보를 설정합니다.
            store.commit('app/SET_TOKEN', token);
            store.commit('app/SET_USER', authentication.user);
            store.commit('app/SET_ROLE_LIST', authentication.roleList);

            // 사용자 메뉴 세션을 서버에서 가져옵니다.
            const session = (await axios({ url: `/api/common/menus/session/${authentication.user.id}`, method: 'get' })).data;

            // 메뉴와 코드 리스트를 Vuex 스토어에 설정합니다.
            store.commit('app/SET_MENU_LIST', session.menuList);
            store.commit('app/SET_TREE_MENU_LIST', session.treeMenuList);
            store.commit('app/SET_CODE_LIST', ((await meta.api.common.code.getCodeList({ page: 1, rowSize: 10000000 })).data.items));
        },

        // 사용자 권한을 제거하는 메서드입니다.
        unauthorize() {
            store.commit('app/SET_TOKEN', null);
            store.commit('app/SET_USER', null);
            store.commit('app/SET_ROLE_LIST', null);
            store.commit('app/SET_MENU_LIST', null);
            store.commit('app/SET_TREE_MENU_LIST', null);
            store.commit('app/SET_CODE_LIST', null);

            // 인증 토큰을 삭제합니다.
            delete axios.defaults.headers.Authorization;
            Basil.localStorage.remove('token');
        },

        // 저장된 토큰을 가져오는 메서드입니다.
        getToken() {
            return Basil.localStorage.get('token');
        },
    },

    // 코드 관련 설정을 다루는 객체입니다.
    config: {
        // 코드 ID로 코드 이름을 가져옵니다.
        getCodeNameById(id: any) {
            const row = (store.getters['app/GET_CODE_LIST']).find((e: any) => e.id === id);
            if (row !== undefined && row != null && row.name !== undefined && row.name != null) {
                return row.name;
            }
            return null;
        },

        // 코드 ID로 코드 값을 가져옵니다.
        getCodeValueById(id: any) {
            const row = (store.getters['app/GET_CODE_LIST']).find((e: any) => e.id === id);
            if (row !== undefined && row != null && row.value !== undefined && row.value != null) {
                return row.value;
            }
            return null;
        },

        // 코드 ID로 코드 설명을 가져옵니다.
        getCodeDescriptionById(id: any) {
            const row = (store.getters['app/GET_CODE_LIST']).find((e: any) => e.id === id);
            if (row !== undefined && row != null && row.description !== undefined && row.description != null) {
                return row.description;
            }
            return null;
        },

        // 코드 ID로 코드 행 전체를 가져옵니다.
        getCodeRowById(id: any) {
            return (store.getters['app/GET_CODE_LIST']).find((e: any) => e.id === id);
        },

        // 부모 ID로 자식 코드 목록을 가져옵니다.
        getCodeListByParentId(parentId: Number) {
            return (store.getters['app/GET_CODE_LIST']).filter((e: any) => e.parentId === parentId);
        },

        // 부모 이름으로 자식 코드 목록을 가져옵니다.
        getCodeListByParentName(parentName: String) {
            const parentRow = (store.getters['app/GET_CODE_LIST']).find((e: any) => e.parentId == null && e.name === parentName);

            if (parentRow !== undefined && parentRow != null && parentRow.id !== undefined && parentRow != null) {
                return (store.getters['app/GET_CODE_LIST']).filter((e: any) => e.parentId === parentRow.id);
            }
            return null;
        },
    },

    // API 요청을 다루는 객체입니다.
    api: {
        common: {
            code: {
                // 코드 목록을 가져옵니다.
                getCodeList(params : object) { return axios({ url: '/api/common/codes', method: 'get', params }); },

                // 코드 ID로 코드를 가져옵니다.
                getCode(id : number) { return axios({ url: `/api/common/codes/${id}`, method: 'get' }); },

                // 코드를 생성합니다.
                createCode(data: object) { return axios({ url: '/api/common/codes', method: 'post', data }); },

                // 코드를 수정합니다.
                modifyCode(id: number, data: object) { return axios({ url: `/api/common/codes/${id}`, method: 'put', data }); },

                // 코드를 삭제합니다.
                removeCode(id: number) { return axios({ url: `/api/common/codes/${id}`, method: 'delete' }); },
            },
            api: {
                // API 목록을 가져옵니다.
                getApiList(params: object) { return axios({ url: '/api/common/apis', method: 'get', params }); },

                // API ID로 API 정보를 가져옵니다.
                getApi(id: number) { return axios({ url: `/api/common/apis/${id}`, method: 'get' }); },

                // API를 생성합니다.
                createApi(data: object) { return axios({ url: '/api/common/apis', method: 'post', data }); },

                // API를 수정합니다.
                modifyApi(id: number, data: object) { return axios({ url: `/api/common/apis/${id}`, method: 'put', data }); },

                // API 목록을 삭제합니다.
                removeApiList(data: object) { return axios({ url: '/api/common/apis', method: 'delete', data }); },
            },
            menu: {
                // 메뉴 목록을 가져옵니다.
                getMenuList(params: object) { return axios({ url: '/api/common/menus', method: 'get', params }); },

                // 메뉴 ID로 메뉴 정보를 가져옵니다.
                getMenu(id: number) { return axios({ url: `/api/common/menus/${id}`, method: 'get' }); },

                // 메뉴를 생성합니다.
                createMenu(data: object) { return axios({ url: '/api/common/menus', method: 'post', data }); },

                // 메뉴를 수정합니다.
                modifyMenu(id: number, data: object) { return axios({ url: `/api/common/menus/${id}`, method: 'put', data }); },

                // 메뉴를 삭제합니다.
                removeMenu(id: number) { return axios({ url: `/api/common/menus/${id}`, method: 'delete' }); },
            },
            role: {
                // 역할 목록을 가져옵니다.
                getRoleList(params: object) { return axios({ url: '/api/common/roles', method: 'get', params }); },

                // 역할 ID로 역할 정보를 가져옵니다.
                getRole(id: number) { return axios({ url: `/api/common/roles/${id}`, method: 'get' }); },

                // 역할을 생성합니다.
                createRole(data: any) { return axios({ url: '/api/common/roles', method: 'post', data }); },

                // 역할을 수정합니다.
                modifyRole(id: number, data: any) { return axios({ url: `/api/common/roles/${id}`, method: 'put', data }); },

                // 모든 의존성을 제거하며 역할을 삭제합니다.
                removeRoleAllDependencyList(data: any) { return axios({ url: '/api/common/roles/delete-all-dependency', method: 'delete', data }); },

                // 역할을 삭제합니다.
                removeRole(id: number) { return axios({ url: `/api/common/roles/${id}`, method: 'delete' }); },
            },
            roleApi: {
                // 역할 API 목록을 가져옵니다.
                getRoleApiList(params: object) { return axios({ url: '/api/common/role-apis', method: 'get', params }); },

                // 역할 ID와 API ID로 역할 API 정보를 가져옵니다.
                getRoleApi(roleId: number, apiId: number) { return axios({ url: `/api/common/role-apis/${roleId},${apiId}`, method: 'get' }); },
            },
            roleMenu: {
                // 역할 메뉴 목록을 가져옵니다.
                getRoleMenuList(params: object) { return axios({ url: '/api/common/role-menus', method: 'get', params }); },

                // 역할 ID와 메뉴 ID로 역할 메뉴 정보를 가져옵니다.
                getRoleMenu(roleId: number, menuId: number) { return axios({ url: `/api/common/role-menus/${roleId},${menuId}`, method: 'get' }); },
            },
            roleUser: {
                // 역할 사용자 목록을 가져옵니다.
                getRoleUserList(params: object) { return axios({ url: '/api/common/role-users', method: 'get', params }); },
            },
            user: {
                // 사용자 목록을 가져옵니다.
                getUserList(params: object) { return axios({ url: '/api/common/users', method: 'get', params }); },

                // 사용자 ID로 사용자 정보를 가져옵니다.
                getUser(id: number) { return axios({ url: `/api/common/users/${id}`, method: 'get' }); },

                // 사용자 이메일을 가져옵니다.
                getUserEmail(params: object) { return axios({ url: '/api/common/users/find-id', method: 'get', params }); },

                // 사용자 정보가 존재하는지 확인합니다.
                getUserInfo(data: object) { return axios({ url: '/api/common/users/user-exists', method: 'post', data }); },

                // 비밀번호를 수정합니다.
                modifyPassword(params: object) { return axios({ url: '/api/common/users', method: 'put', params }); },

                // 사용자를 생성합니다.
                createUser(data: any) { return axios({ url: '/api/common/users', method: 'post', data }); },
            },
            theme: {
                // 테마 목록을 가져옵니다.
                getThemeList(params: object) { return axios({ url: '/api/common/themes', method: 'get', params }); },

                // 메가 테마 목록을 가져옵니다.
                getThemeMegaList(params: object) { return axios({ url: '/api/common/themes/theme-mega', method: 'get', params }); },

                // 주요 테마 목록을 가져옵니다.
                getThemeMainList(params: object) { return axios({ url: '/api/common/themes/theme-main', method: 'get', params }); },

                // 상위 카테고리 목록을 가져옵니다.
                getTopCategoryList() { return axios({ url: '/api/common/themes/top-categories', method: 'get' }); },

                // 테마를 수정합니다.
                modifyTheme(data: any) { return axios({ url: '/api/common/themes', method: 'put', data }); },
            },
            themeCategory: {
                // 테마 카테고리 목록을 가져옵니다.
                getCateList(params: object) { return axios({ url: '/api/common/theme-category', method: 'get', params }); },

                // 테마 카테고리 리스트를 가져옵니다.
                getThemeCategoryList(params: object) { return axios({ url: '/api/common/theme-category/list', method: 'get', params }); },

                // 테마 카테고리 수 목록을 가져옵니다.
                getThemeCountList(params: object) { return axios({ url: '/api/common/theme-category/list-count', method: 'get', params }); },
            },
            strategyCategory: {
                // AI 포트폴리오 목록을 가져옵니다.
                getAiportfolioList(params: object) { return axios({ url: '/api/common/strategy-category/ai-portfolio-list', method: 'get', params }); },

                // 전략 카테고리 목록을 가져옵니다.
                getStrategyCategoryList(params: object) { return axios({ url: '/api/common/strategy-category/strategy-category-list', method: 'get', params }); },
            },
            aiPortfolio: {
                // AI 포트폴리오 리스트를 가져옵니다.
                getAiPortfolioList(params: object) { return axios({ url: '/api/common/ai-portfolio/list', method: 'get', params }); },

                // AI 포트폴리오 가격 목록을 가져옵니다.
                getAiPortfolioPriceList(params: object) { return axios({ url: '/api/common/ai-portfolio/price-list', method: 'get', params }); },
            },
            asset: {
                // 자산 목록을 가져옵니다.
                getAssetList(params: object) { return axios({ url: '/api/common/assets', method: 'get', params }); },

                // 자산 정보를 가져옵니다.
                getAsset(params: object) { return axios({ url: '/api/common/assets/info', method: 'get', params }); },
            },
            sector: {
                // 섹터 목록을 가져옵니다.
                getSectorList(params: object) { return axios({ url: '/api/common/sector/list', method: 'get', params }); },
            },
            strategyHoldings: {
                // 전략 보유 목록을 가져옵니다.
                getstrategyHoldingsList(params: object) { return axios({ url: '/api/common/strategy-holdings', method: 'get', params }); },

                // 전략 보유 ID로 정보를 가져옵니다.
                getstrategyHoldings(id: number) { return axios({ url: `/api/common/strategy-holdings/${id}`, method: 'get' }); },

                // 보유 자산 클래스 합계를 최대 날짜로 가져옵니다.
                getHoldingsAssetClassSumByMaxDate(id: number) { return axios({ url: `/api/common/strategy-holdings/asset-class-sum/${id}`, method: 'get' }); },

                // 보유 섹터 합계를 최대 날짜로 가져옵니다.
                getHoldingsSectorSumByMaxDate(id: number) { return axios({ url: `/api/common/strategy-holdings/sector-sum/${id}`, method: 'get' }); },

                // 보유 자산 목록을 가져옵니다.
                getHoldingsAssetList(params: object) { return axios({ url: '/api/common/strategy-holdings/asset', method: 'get', params }); },

                // 자산 클래스 그룹으로 보유 자산 목록을 가져옵니다.
                getHoldingsAssetListGroupByAssetClass(id: number) { return axios({ url: `/api/common/strategy-holdings/asset/group-asset-class/${id}`, method: 'get' }); },
            },
            strategy: {
                // 전략 목록을 가져옵니다.
                getStrategyList(params: object) { return axios({ url: '/api/common/strategy', method: 'get', params }); },

                // 검색 리스트를 가져옵니다.
                getSearchList(params: object) { return axios({ url: '/api/common/strategy/list', method: 'get', params }); },

                // 전략 정보를 가져옵니다.
                getStrategy(params: object) { return axios({ url: '/api/common/strategy/info', method: 'get', params }); },

                // 전략 차트를 가져옵니다.
                getStrategychart(params: object) { return axios({ url: '/api/common/strategy/chart', method: 'get', params }); },
            },
            insights: {
                // 시장 위험 모니터 데이터를 가져옵니다.
                getMarketRiskMonitor(params: object) { return axios({ url: '/api/common/insights/market-risk-monitor', method: 'get', params }); },
            },
            demo: {
                // 데모 리스트를 가져옵니다.
                getDemoList(params: object) { return axios({ url: '/api/common/demo/list', method: 'get', params }); },

                // 데모 ID로 데모 정보를 가져옵니다.
                getDemo(id : number) { return axios({ url: `/api/common/demo/${id}`, method: 'get' }); },

                // 데모를 생성합니다.
                createDemo(data: object) { return axios({ url: '/api/common/demo', method: 'post', data }); },

                // 데모를 수정합니다.
                modifyDemo(id: number, data: object) { return axios({ url: `/api/common/demo/${id}`, method: 'put', data }); },

                // 데모를 삭제합니다.
                removeDemo(id: number) { return axios({ url: `/api/common/demo/${id}`, method: 'delete' }); },
            },
        },
        app: {
            account: {
                // 계정을 생성합니다.
                createAccount(data: object) { return axios({ url: '/api/app/accounts', method: 'post', data }); },

                // 계정을 수정합니다.
                modifyAccount(data: object) { return axios({ url: '/api/app/accounts', method: 'put', data }); },

                // 계정을 삭제합니다.
                removeAccount(id: number) { return axios({ url: `/api/app/accounts/${id}`, method: 'delete' }); },
            },
            RoleAndRoleMenuAndRoleApi: {
                // 역할 및 역할 메뉴 및 역할 API를 생성합니다.
                createRoleAndRoleMenuAndRoleApi(data: object) { return axios({ url: '/api/app/role-menu-apis', method: 'post', data }); },

                // 역할 및 역할 메뉴 및 역할 API를 수정합니다.
                modifyRoleAndRoleMenuAndRoleApi(data: object) { return axios({ url: '/api/app/role-menu-apis', method: 'put', data }); },
            },
        },
        util: {
            menu: {
                // 트리 메뉴 목록을 가져옵니다.
                getTreeMenuList(params: object) { return axios({ url: '/api/util/menus/tree-menus', method: 'get', params }); },

                // 기본 메뉴 목록을 가져옵니다.
                getDefaultMenuList() { return axios({ url: '/api/menu', method: 'get' }); },
            },
            code: {
                // 트리 코드 목록을 가져옵니다.
                getTreeCodeList(params: object) { return axios({ url: '/api/util/codes/tree-codes', method: 'get', params }); },
            },
        },
    },

    // 유틸리티 함수 모음입니다.
    util: {
        // 정렬을 위한 함수입니다.
        sort(sortBy: string, sortDesc: string) {
            const str = [];
            if (sortBy.length > 0 && sortDesc.length > 0) {
                for (let i = 0; i < sortBy.length; i += 1) {
                    str.push(`${sortBy[i]},${sortDesc[i] ? 'desc' : 'asc'}`);
                }
            }
            return str;
        },

        // 두 날짜가 같은 날인지 확인하는 함수입니다.
        isSameDate(date1: any, date2: any) {
            return date1.getFullYear() === date2.getFullYear()
                && date1.getMonth() === date2.getMonth()
                && date1.getDate() === date2.getDate();
        },
    },
};

// 메타 데이터를 초기화합니다.
meta.init();

// 메타 데이터를 기본 내보내기로 내보냅니다.
export {
    meta as default,
};

// Axios 요청 인터셉터 예제 (주석 처리됨)
// axios.interceptors.request.use(
//     async (config) => {
//         const token = Basil.localStorage.get('token');

//         if (token != null) {
//             // const { exp }: any = jwtDecode(token); // Access Token 만료시간
//             // const accessTokenExp = new Date(exp * 1000);

//             const rft: any = jwtDecode(Vue.$cookies.get('refresh_token')); // Refresh Token 만료시간

//             const refreshTokenExp = new Date(rft.exp * 1000);

//             // /** Refresh Token 일주일 전일경우 재발급 */
//             // const date1 = new Date();
//             // const date2 = new Date(new Date().setDate(refreshTokenExp.getDate() - 7));
//             // if (meta.util.isSameDate(date1, date2)) {
//             // }

//             if (refreshTokenExp <= new Date()) {
//                 await meta.alert('토큰이 만료되었습니다. 로그인페이지로 이동합니다.');
//                 await meta.auth.logout(token);
//                 router.push('/sign-in');
//             }
//         }

//         return config;
//     },
//     (error) => error,
// );

// 다중 인터셉트 처리를 위한 변수와 함수들
let isTokenRefreshing = false; // 토큰이 갱신 중인지 여부를 추적합니다.
let refreshSubscribers: any = []; // 토큰 갱신 후 실행될 콜백 함수 목록입니다.

// 새로운 액세스 토큰이 발급되었을 때 모든 대기 중인 요청에 새 토큰을 적용합니다.
const onTokenRefreshed = (accessToken: any) => {
    refreshSubscribers.map((callback: any) => callback(accessToken));
    refreshSubscribers = []; // 콜백 함수 목록을 초기화합니다.
};

// 액세스 토큰 갱신을 대기 중인 요청을 콜백 목록에 추가합니다.
const addRefreshSubscriber = (callback: any) => {
    refreshSubscribers.push(callback);
};

// Axios 응답 인터셉터 설정
axios.interceptors.response.use(
    (response) => response, // 정상 응답은 그대로 반환합니다.
    async (error) => {
        const { config } = error; // 에러 발생 시 요청 설정을 가져옵니다.
        const originalRequest = config; // 원본 요청을 보관합니다.

        const accessToken = Basil.localStorage.get('token'); // 저장된 액세스 토큰을 가져옵니다.
        const refreshToken = Vue.$cookies.get('refresh_token'); // 저장된 리프레시 토큰을 가져옵니다.

        // 오류 응답이 있고, 오류 메시지가 'Access Token Expired'인 경우
        if (error.response && error.response.headers.msg) {
            if (error.response.status != null && error.response.status === 401 && error.response.headers.msg === 'Access Token Expired') {
                /** Access Token 재발급 전 Request 배열로 만들기 */
                const retryOriginalRequest = new Promise((resolve) => {
                    addRefreshSubscriber((newAccessToken: any) => {
                        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`; // 새 액세스 토큰을 헤더에 설정합니다.
                        resolve(axios(originalRequest)); // 원본 요청을 다시 보냅니다.
                    });
                });

                if (!isTokenRefreshing) { // 토큰이 현재 갱신 중이 아니라면
                    /** Access Token 재발급 */
                    const { data } = await axios({
                        url: '/api/app/token/new-access-token',
                        method: 'post',
                        data: {
                            accessToken,
                            refreshToken,
                        },
                    });

                    /** RefreshToken 만료시 */
                    if (data.refreshTokenExpired) {
                        await meta.alert('토큰이 만료되었습니다. 로그인페이지로 이동합니다.');
                        await meta.auth.logout(accessToken);
                        router.push('/sign-in');
                        return Promise.reject(error); // 오류를 반환하여 현재 요청을 중단합니다.
                    }

                    isTokenRefreshing = false; // 토큰 갱신 완료 후 상태를 초기화합니다.
                    Basil.localStorage.set('token', data.accessToken); // 새 액세스 토큰을 저장합니다.
                    axios.defaults.headers.Authorization = `Bearer ${data.accessToken}`; // Axios 기본 헤더에 새 토큰을 설정합니다.
                    onTokenRefreshed(data.accessToken); // 모든 대기 중인 요청을 재시도합니다.
                }
                return retryOriginalRequest; // 재발급 후 대기 중이던 요청을 반환합니다.
            }
        }
        return Promise.reject(error); // 다른 오류는 그대로 반환합니다.
    },
);
