// Polyfill을 가져옵니다. (ES6+ 기능을 지원하지 않는 브라우저를 위해)
import 'core-js';
import 'regenerator-runtime';

// Vuetify CSS 스타일을 가져옵니다.
import 'vuetify/dist/vuetify.min.css';

// Material Design Icons 폰트를 가져옵니다.
import '@mdi/font/css/materialdesignicons.min.css';

// Vue 프레임워크를 가져옵니다.
import Vue from 'vue';

// Vuex 상태 관리 라이브러리를 가져옵니다.
import Vuex from 'vuex';

// Vue Router 설정 파일을 가져옵니다.
import VueRouter from '@/config/vue-router';

// Vuetify 설정 파일을 가져옵니다.
import Vuetify from '@/config/vuetify';

// Vuex 스토어 설정 파일을 가져옵니다.
import Store from '@/config/store';

// 사용자 정의 알림 컴포넌트를 가져옵니다.
import alert from '@/pages/components/alert.vue';

// 사용자 정의 확인 컴포넌트를 가져옵니다.
import confirm from '@/pages/components/confirm.vue';

// 사용자 정의 데이터 테이블 컴포넌트를 가져옵니다.
import dataTableCustomComponent from '@/pages/components/data-table-custom-component.vue';

// 사용자 정의 선택 컴포넌트를 가져옵니다.
import selectComponent from '@/pages/components/select-component.vue';

// 사용자 정의 날짜 선택기 컴포넌트를 가져옵니다.
import datePicker from '@/pages/components/date-picker.vue';

// 메인 애플리케이션 컴포넌트를 가져옵니다.
import App from '@/routes/App.vue';

// Vue Cookies 플러그인을 가져옵니다.
import VueCookies from 'vue-cookies';

// 전역적으로 사용할 Vue 컴포넌트를 등록합니다.
Vue.component('alert-component', alert); // 'alert-component' 이름으로 알림 컴포넌트를 등록합니다.
Vue.component('confirm-component', confirm); // 'confirm-component' 이름으로 확인 컴포넌트를 등록합니다.
Vue.component('data-table-custorm-component', dataTableCustomComponent); // 'data-table-custorm-component' 이름으로 데이터 테이블 컴포넌트를 등록합니다.
Vue.component('custom-select-component', selectComponent); // 'custom-select-component' 이름으로 선택 컴포넌트를 등록합니다.
Vue.component('picker-date-picker-component', datePicker); // 'picker-date-picker-component' 이름으로 날짜 선택기 컴포넌트를 등록합니다.

// Vue에 Vuex 플러그인을 추가합니다.
Vue.use(Vuex);

// Vue에 Vue Cookies 플러그인을 추가합니다.
Vue.use(VueCookies);

// 쿠키 설정을 구성합니다.
Vue.$cookies.config('14d', '', '', true); // 쿠키 만료 시간을 14일로 설정하고, secure 옵션을 활성화합니다.

// 새로운 Vue 인스턴스를 생성합니다.
new Vue({
    // '#app'이라는 id를 가진 HTML 요소에 Vue 인스턴스를 마운트합니다.
    el: '#app',

    // App 컴포넌트를 렌더링합니다.
    render: (h) => h(App),

    // Vuetify 플러그인을 설정합니다.
    vuetify: Vuetify,

    // Vuex 스토어를 설정합니다.
    store: Store,

    // Vue Router를 설정합니다.
    router: VueRouter,
});
