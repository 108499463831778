!(function () {
  var e = function (t) {
    return e.utils.extend({}, e.plugins, new e.Storage().init(t));
  };
  (e.version = "0.4.11"),
    (e.utils = {
      extend: function () {
        for (
          var e = "object" == typeof arguments[0] ? arguments[0] : {}, t = 1;
          t < arguments.length;
          t++
        )
          if (arguments[t] && "object" == typeof arguments[t])
            for (var n in arguments[t]) e[n] = arguments[t][n];
        return e;
      },
      each: function (e, t, n) {
        if (this.isArray(e)) {
          for (var i = 0; i < e.length; i++)
            if (!1 === t.call(n, e[i], i)) return;
        } else if (e) for (var r in e) if (!1 === t.call(n, e[r], r)) return;
      },
      tryEach: function (e, t, n, i) {
        this.each(
          e,
          function (e, r) {
            try {
              return t.call(i, e, r);
            } catch (t) {
              if (this.isFunction(n))
                try {
                  n.call(i, e, r, t);
                } catch (e) {}
            }
          },
          this
        );
      },
      registerPlugin: function (t) {
        e.plugins = this.extend(t, e.plugins);
      },
      getTypeOf: function (e) {
        return void 0 === e || null === e
          ? "" + e
          : Object.prototype.toString
              .call(e)
              .replace(/^\[object\s(.*)\]$/, function (e, t) {
                return t.toLowerCase();
              });
      },
    });
  for (
    var t = [
        "Arguments",
        "Boolean",
        "Function",
        "String",
        "Array",
        "Number",
        "Date",
        "RegExp",
        "Undefined",
        "Null",
      ],
      n = 0;
    n < t.length;
    n++
  )
    e.utils["is" + t[n]] = (function (t) {
      return function (n) {
        return e.utils.getTypeOf(n) === t.toLowerCase();
      };
    })(t[n]);
  (e.plugins = {}),
    (e.options = e.utils.extend(
      {
        namespace: "b45i1",
        storages: ["local", "cookie", "session", "memory"],
        expireDays: 365,
        keyDelimiter: ".",
      },
      window.Basil ? window.Basil.options : {}
    )),
    (e.Storage = function () {
      var t = "b45i1" + (Math.random() + 1).toString(36).substring(7),
        n = {},
        i = function (t) {
          var n = e.utils.getTypeOf(t);
          return ("string" === n && t) || "number" === n || "boolean" === n;
        },
        r = function (t) {
          return e.utils.isArray(t) ? t : e.utils.isString(t) ? [t] : [];
        },
        o = function (t, n, r) {
          var o = "";
          return (
            i(n)
              ? (o += n)
              : e.utils.isArray(n) &&
                (o = (n = e.utils.isFunction(n.filter) ? n.filter(i) : n).join(
                  r
                )),
            o && i(t) ? t + r + o : o
          );
        },
        s = function (e, t, n) {
          return i(e) ? t.replace(new RegExp("^" + e + n), "") : t;
        },
        u = {
          engine: null,
          check: function () {
            try {
              window[this.engine].setItem(t, !0),
                window[this.engine].removeItem(t);
            } catch (e) {
              return !1;
            }
            return !0;
          },
          set: function (e, t, n) {
            if (!e) throw Error("invalid key");
            window[this.engine].setItem(e, t);
          },
          get: function (e) {
            return window[this.engine].getItem(e);
          },
          remove: function (e) {
            window[this.engine].removeItem(e);
          },
          reset: function (e) {
            for (var t, n = 0; n < window[this.engine].length; n++)
              (t = window[this.engine].key(n)),
                (e && 0 !== t.indexOf(e)) || (this.remove(t), n--);
          },
          keys: function (e, t) {
            for (var n, i = [], r = 0; r < window[this.engine].length; r++)
              (n = window[this.engine].key(r)),
                (e && 0 !== n.indexOf(e)) || i.push(s(e, n, t));
            return i;
          },
        };
      return (
        (n.local = e.utils.extend({}, u, { engine: "localStorage" })),
        (n.session = e.utils.extend({}, u, { engine: "sessionStorage" })),
        (n.memory = {
          _hash: {},
          check: function () {
            return !0;
          },
          set: function (e, t, n) {
            if (!e) throw Error("invalid key");
            this._hash[e] = t;
          },
          get: function (e) {
            return this._hash[e] || null;
          },
          remove: function (e) {
            delete this._hash[e];
          },
          reset: function (e) {
            for (var t in this._hash)
              (e && 0 !== t.indexOf(e)) || this.remove(t);
          },
          keys: function (e, t) {
            var n = [];
            for (var i in this._hash)
              (e && 0 !== i.indexOf(e)) || n.push(s(e, i, t));
            return n;
          },
        }),
        (n.cookie = {
          check: function (e) {
            if (!navigator.cookieEnabled) return !1;
            if (window.self !== window.top) {
              var n = "thirdparty.check=" + Math.round(1e3 * Math.random());
              return (
                (document.cookie = n + "; path=/"),
                -1 !== document.cookie.indexOf(n)
              );
            }
            if (e && e.secure)
              try {
                this.set(t, t, e);
                var i = this.get(t) === t;
                return this.remove(t), i;
              } catch (e) {
                return !1;
              }
            return !0;
          },
          set: function (e, t, n) {
            if (!this.check()) throw Error("cookies are disabled");
            if (((n = n || {}), !e)) throw Error("invalid key");
            var i = encodeURIComponent(e) + "=" + encodeURIComponent(t);
            if (n.expireDays) {
              var r = new Date();
              r.setTime(r.getTime() + 24 * n.expireDays * 60 * 60 * 1e3),
                (i += "; expires=" + r.toGMTString());
            }
            if (n.domain && n.domain !== document.domain) {
              var o = n.domain.replace(/^\./, "");
              if (-1 === document.domain.indexOf(o) || o.split(".").length <= 1)
                throw Error("invalid domain");
              i += "; domain=" + n.domain;
            }
            n.sameSite &&
              ["lax", "strict", "none"].includes(n.sameSite.toLowerCase()) &&
              (i += "; SameSite=" + n.sameSite),
              !0 === n.secure && (i += "; Secure"),
              (document.cookie = i + "; path=/");
          },
          get: function (e) {
            if (!this.check()) throw Error("cookies are disabled");
            for (
              var t,
                n = encodeURIComponent(e),
                i = document.cookie ? document.cookie.split(";") : [],
                r = i.length - 1;
              r >= 0;
              r--
            )
              if (0 === (t = i[r].replace(/^\s*/, "")).indexOf(n + "="))
                return decodeURIComponent(t.substring(n.length + 1, t.length));
            return null;
          },
          remove: function (e) {
            this.set(e, "", { expireDays: -1 });
            for (var t = document.domain.split("."), n = t.length; n > 1; n--)
              this.set(e, "", {
                expireDays: -1,
                domain: "." + t.slice(-n).join("."),
              });
          },
          reset: function (e) {
            for (
              var t,
                n,
                i = document.cookie ? document.cookie.split(";") : [],
                r = 0;
              r < i.length;
              r++
            )
              (n = (t = i[r].replace(/^\s*/, "")).substr(0, t.indexOf("="))),
                (e && 0 !== n.indexOf(e)) || this.remove(n);
          },
          keys: function (e, t) {
            if (!this.check()) throw Error("cookies are disabled");
            for (
              var n,
                i,
                r = [],
                o = document.cookie ? document.cookie.split(";") : [],
                u = 0;
              u < o.length;
              u++
            )
              (n = o[u].replace(/^\s*/, "")),
                (i = decodeURIComponent(n.substr(0, n.indexOf("=")))),
                (e && 0 !== i.indexOf(e)) || r.push(s(e, i, t));
            return r;
          },
        }),
        {
          init: function (e) {
            return this.setOptions(e), this;
          },
          setOptions: function (t) {
            this.options = e.utils.extend({}, this.options || e.options, t);
          },
          support: function (e) {
            return n.hasOwnProperty(e);
          },
          check: function (e) {
            return !!this.support(e) && n[e].check(this.options);
          },
          set: function (t, i, s) {
            if (
              ((s = e.utils.extend({}, this.options, s)),
              !(t = o(s.namespace, t, s.keyDelimiter)))
            )
              return !1;
            var u;
            i = !0 === s.raw ? i : ((u = i), JSON.stringify(u));
            var a = null;
            return (
              e.utils.tryEach(
                r(s.storages),
                function (e, r) {
                  return n[e].set(t, i, s), (a = e), !1;
                },
                null,
                this
              ),
              !!a &&
                (e.utils.tryEach(
                  r(s.storages),
                  function (e, i) {
                    e !== a && n[e].remove(t);
                  },
                  null,
                  this
                ),
                !0)
            );
          },
          get: function (t, i) {
            if (
              ((i = e.utils.extend({}, this.options, i)),
              !(t = o(i.namespace, t, i.keyDelimiter)))
            )
              return null;
            var s = null;
            return (
              e.utils.tryEach(
                r(i.storages),
                function (e, r) {
                  if (null !== s) return !1;
                  var o;
                  (s = n[e].get(t, i) || null),
                    (s = !0 === i.raw ? s : (o = s) ? JSON.parse(o) : null);
                },
                function (e, t, n) {
                  s = null;
                },
                this
              ),
              s
            );
          },
          remove: function (t, i) {
            (i = e.utils.extend({}, this.options, i)),
              (t = o(i.namespace, t, i.keyDelimiter)) &&
                e.utils.tryEach(
                  r(i.storages),
                  function (e) {
                    n[e].remove(t);
                  },
                  null,
                  this
                );
          },
          reset: function (t) {
            (t = e.utils.extend({}, this.options, t)),
              e.utils.tryEach(
                r(t.storages),
                function (e) {
                  n[e].reset(t.namespace);
                },
                null,
                this
              );
          },
          keys: function (e) {
            e = e || {};
            var t = [];
            for (var n in this.keysMap(e)) t.push(n);
            return t;
          },
          keysMap: function (t) {
            t = e.utils.extend({}, this.options, t);
            var i = {};
            return (
              e.utils.tryEach(
                r(t.storages),
                function (r) {
                  e.utils.each(
                    n[r].keys(t.namespace, t.keyDelimiter),
                    function (t) {
                      (i[t] = e.utils.isArray(i[t]) ? i[t] : []), i[t].push(r);
                    },
                    this
                  );
                },
                null,
                this
              ),
              i
            );
          },
        }
      );
    }),
    (e.memory = new e.Storage().init({
      storages: "memory",
      namespace: null,
      raw: !0,
    })),
    (e.cookie = new e.Storage().init({
      storages: "cookie",
      namespace: null,
      raw: !0,
    })),
    (e.localStorage = new e.Storage().init({
      storages: "local",
      namespace: null,
      raw: !0,
    })),
    (e.sessionStorage = new e.Storage().init({
      storages: "session",
      namespace: null,
      raw: !0,
    })),
    (window.Basil = e),
    "function" == typeof define && define.amd
      ? define(function () {
          return e;
        })
      : "undefined" != typeof module && module.exports && (module.exports = e);
})();
