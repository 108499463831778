// Vue와 VueRouter를 불러옵니다.
import Vue from 'vue';
import VueRouter from 'vue-router';

// 인증 관련 메타 정보를 불러옵니다.
import meta from '@/api/meta';

// Vuex 스토어를 불러옵니다.
import store from '@/config/store';

// VueRouter를 Vue 애플리케이션에 추가합니다.
Vue.use(VueRouter);

// VueRouter 인스턴스를 생성합니다.
const router = new VueRouter({
    // 'history' 모드를 사용하여 해시(#) 없는 URL을 사용합니다.
    mode: 'history',

    // 라우트 목록을 정의합니다.
    routes: [
        {
            // '/portfolios/all-portfolios' 경로에 대한 설정입니다.
            path: '/portfolios/all-portfolios',

            // 동일한 경로로 리다이렉트합니다.
            redirect: '/portfolios/all-portfolios',

            // 레이아웃 컴포넌트를 비동기로 가져옵니다.
            component: () => import('@/layouts/Base.vue'),

            // 하위 라우트를 정의합니다.
            children: [
                {
                    // '/portfolios/all-portfolios' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/portfolios/all-portfolios',
                    component: () => import('@/pages/portfolio/all-portfolio/main.vue'),
                },
                {
                    // '/portfolios/compare-portfolios' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/portfolios/compare-portfolios',
                    component: () => import('@/pages/portfolio/compare-portfolio/main.vue'),
                },
                {
                    // '/portfolios/portfolio-detail' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/portfolios/portfolio-detail',
                    component: () => import('@/pages/portfolio/portfolio-detail/main.vue'),
                },
                {
                    // '/portfolios/theme-machine' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/portfolios/theme-machine',
                    component: () => import('@/pages/portfolio/theme-machine/main.vue'),
                },
                {
                    // '/portfolios/theme-machine/qna' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/portfolios/theme-machine/qna',
                    component: () => import('@/pages/portfolio/theme-machine/qna/main.vue'),
                },
                {
                    // '/portfolios/theme-machine/results' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/portfolios/theme-machine/results',
                    component: () => import('@/pages/portfolio/theme-machine/result/main.vue'),
                },
                {
                    // '/portfolios/theme-machine/themetic-growth-etfs' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/portfolios/theme-machine/themetic-growth-etfs',
                    component: () => import('@/pages/portfolio/theme-machine/themetic-growth-etfs/main.vue'),
                },
                {
                    // '/insights/market-view/monthly-commentary' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/monthly-commentary',
                    component: () => import('@/pages/insights/market-view/monthly-commentary/main.vue'),
                },
                {
                    // '/insights/market-view/weekly-commentary' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/weekly-commentary',
                    component: () => import('@/pages/insights/market-view/weekly-commentary/main.vue'),
                },
                {
                    // '/insights/market-view/market-risk/list' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/market-risk/list',
                    component: () => import('@/pages/insights/market-view/market-risk/list/main.vue'),
                },
                {
                    // '/insights/market-view/market-risk/detail' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/market-risk/detail',
                    component: () => import('@/pages/insights/market-view/market-risk/detail/main.vue'),
                },
                {
                    // '/test' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/test',
                    component: () => import('@/pages/test/main.vue'),
                },
                {
                    // '/test-chart' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/test-chart',
                    component: () => import('@/pages/test/test.vue'),
                },
            ],
        },
        {
            // 루트('/') 경로에 대한 설정입니다.
            path: '/',
            
            // '/dneuro' 경로로 리다이렉트합니다.
            redirect: '/dneuro', // who-we-are

            // 레이아웃 컴포넌트를 비동기로 가져옵니다.
            component: () => import('@/layouts/Single.vue'),

            // 하위 라우트를 정의합니다.
            children: [
                {
                    // '/main' 경로를 '/dneuro'로 리다이렉트합니다.
                    path: '/main',
                    redirect: '/dneuro', // who-we-are
                },
                {
                    // '/dneuro' 경로에 대한 컴포넌트를 설정합니다. (who-we-are)
                    path: '/dneuro',
                    component: () => import('@/pages/about/who-we-are/main.vue'),
                },
                {
                    // '/news-and-insights' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/news-and-insights',
                    component: () => import('@/pages/about/news-and-insights/main.vue'),
                },
                {
                    // '/education' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/education',
                    component: () => import('@/pages/about/education/main.vue'),
                },
                {
                    // '/service' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/service',
                    component: () => import('@/pages/about/service/main.vue'),
                },
            ],
        },
        {
            // '/blank' 경로에 대한 설정입니다.
            path: '/blank',

            // 동일한 경로로 리다이렉트합니다.
            redirect: '/blank',

            // 레이아웃 컴포넌트를 비동기로 가져옵니다.
            component: () => import('@/layouts/Blank.vue'),

            // 하위 라우트를 정의합니다.
            children: [
                {
                    // '/sign-in' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/sign-in',
                    component: () => import('@/pages/user/sign-in/main.vue'),
                },
                {
                    // '/sign-up' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/sign-up',
                    component: () => import('@/pages/user/sign-up/main.vue'),
                },
                {
                    // '/id-find' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/id-find',
                    component: () => import('@/pages/user/id-find/main.vue'),
                },
                {
                    // '/pw-find' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/pw-find',
                    component: () => import('@/pages/user/pw-find/main.vue'),
                },
                {
                    // '/reports/get-invested' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/reports/get-invested',
                    component: () => import('@/pages/report/get-invested/main.vue'),
                },
                {
                    // 와일드카드 경로로, 다른 모든 경로에 대한 예외 페이지를 설정합니다.
                    path: '*',
                    component: () => import('@/pages/exception/main.vue'),
                },
                {
                    // '/insights/market-view/market-risk/monitor-report-1' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/market-risk/monitor-report-1',
                    component: () => import('@/pages/insights/market-view/market-risk/report/report01.vue'),
                },
                {
                    // '/insights/market-view/market-risk/monitor-report-2' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/market-risk/monitor-report-2',
                    component: () => import('@/pages/insights/market-view/market-risk/report/report02.vue'),
                },
                {
                    // '/insights/market-view/market-risk/monitor-report-3' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/market-risk/monitor-report-3',
                    component: () => import('@/pages/insights/market-view/market-risk/report/report03.vue'),
                },
                {
                    // '/insights/market-view/market-risk/monitor-report-4' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/market-risk/monitor-report-4',
                    component: () => import('@/pages/insights/market-view/market-risk/report/report04.vue'),
                },
                {
                    // '/insights/market-view/market-risk/monitor-report-5' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/market-risk/monitor-report-5',
                    component: () => import('@/pages/insights/market-view/market-risk/report/report05.vue'),
                },
                {
                    // '/insights/market-view/market-risk/monitor-report-6' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/market-risk/monitor-report-6',
                    component: () => import('@/pages/insights/market-view/market-risk/report/report06.vue'),
                },
                {
                    // '/insights/market-view/market-risk/monitor-report-7' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/market-risk/monitor-report-7',
                    component: () => import('@/pages/insights/market-view/market-risk/report/report07.vue'),
                },
                {
                    // '/insights/market-view/market-risk/monitor-report-8' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/market-risk/monitor-report-8',
                    component: () => import('@/pages/insights/market-view/market-risk/report/report08.vue'),
                },
                {
                    // '/insights/market-view/market-risk/monitor-report-total' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/market-risk/monitor-report-total',
                    component: () => import('@/pages/insights/market-view/market-risk/report/reportTotal.vue'),
                },
                {
                    // '/insights/market-view/monthly-commentary-report' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/monthly-commentary-report',
                    component: () => import('@/pages/insights/market-view/monthly-commentary/monthly-commentary-report/main.vue'),
                },
                {
                    // '/insights/market-view/weekly-commentary-report' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/insights/market-view/weekly-commentary-report',
                    component: () => import('@/pages/insights/market-view/weekly-commentary/weekly-commentary-report/main.vue'),
                },
                {
                    // '/portfolios/portfolio-detail/report' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/portfolios/portfolio-detail/report',
                    component: () => import('@/pages/portfolio/portfolio-detail/report/main.vue'),
                },
            ],
        },
        {
            // '/settings' 경로에 대한 설정입니다.
            path: '/settings',

            // '/settings/apis' 경로로 리다이렉트합니다.
            redirect: '/settings/apis',

            // 레이아웃 컴포넌트를 비동기로 가져옵니다.
            component: () => import('@/layouts/Admin.vue'),

            // 하위 라우트를 정의합니다.
            children: [
                {
                    // '/settings/apis' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/settings/apis',
                    component: () => import('@/pages/setting/api/main.vue'),
                },
                {
                    // '/settings/apis/detail' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/settings/apis/detail',
                    component: () => import('@/pages/setting/api/main-detail.vue'),
                },
                {
                    // '/settings/menus' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/settings/menus',
                    component: () => import('@/pages/setting/menu/main.vue'),
                },
                {
                    // '/settings/menus' 경로에 대한 컴포넌트를 설정합니다. (중복)
                    path: '/settings/menus',
                    component: () => import('@/pages/setting/menu/main.vue'),
                },
                {
                    // '/settings/roles' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/settings/roles',
                    component: () => import('@/pages/setting/role/main.vue'),
                },
                {
                    // '/settings/roles/detail' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/settings/roles/detail',
                    component: () => import('@/pages/setting/role/main-detail.vue'),
                },
                {
                    // '/settings/users' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/settings/users',
                    component: () => import('@/pages/setting/user/main.vue'),
                },
                {
                    // '/settings/codes' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/settings/codes',
                    component: () => import('@/pages/setting/code/main.vue'),
                },
                {
                    // '/settings/themes' 경로에 대한 컴포넌트를 설정합니다.
                    path: '/settings/themes',
                    component: () => import('@/pages/setting/theme/main.vue'),
                },
            ],
        },
    ],

    // 라우트 변경 시 스크롤 위치를 초기화하는 방법을 정의합니다.
    scrollBehavior() {
        // 페이지 이동 시 스크롤을 맨 위로 이동합니다.
        return { x: 0, y: 0 };
    },
});

// 라우터의 'beforeEach' 훅을 사용하여 각 라우트 변경 전에 실행되는 로직을 설정합니다.
router.beforeEach(async (to, from, next) => {
    // 인증 토큰을 가져옵니다.
    const token = meta.auth.getToken();

    // 트리 메뉴 목록과 트리 메뉴를 저장할 변수입니다.
    let treeMenuList;
    let treeMenu;

    // 현재 라우트 경로에 대한 정규식 객체를 가져옵니다.
    const regex = to.matched.length > 0 ? to.matched[to.matched.length - 1].regex : null;

    // 사용자가 인증되었는지를 나타내는 변수입니다.
    let authenticated;

    // 기본 페이지 제목입니다.
    const mainTitle = 'Dnuero';

    // 하위 제목과 전체 제목 경로를 저장할 변수들입니다.
    let subTitle;
    let title;
    let titlePath;

    // 반복문에서 사용할 인덱스 변수입니다.
    let i;

    // Vuex 스토어의 임시 변수를 만듭니다.
    const storeTemp: any = store;

    // 사용자가 인증된 경우
    if (await meta.auth.authenticated(token)) {
        // 사용자의 권한을 설정합니다.
        await meta.auth.authorize(token);

        // 트리 메뉴 리스트를 Vuex 스토어에서 가져옵니다.
        treeMenuList = storeTemp.state.app.treeMenuList;

        // 사용자가 접근 가능한 메뉴를 확인합니다.
        authenticated = false;
        for (i = 0; i < treeMenuList.length; i += 1) {
            treeMenu = treeMenuList[i];

            // 현재 경로가 메뉴 경로와 일치하는지 검사합니다.
            if (regex && regex.test(treeMenu.path)) {
                authenticated = true;
                break;
            }
        }

        // 현재 경로와 일치하는 메뉴 이름을 제목 경로로 추가합니다.
        titlePath = [];
        for (i = 0; i < treeMenuList.length; i += 1) {
            treeMenu = treeMenuList[i];
            if (new RegExp(`${treeMenu.path}.*`, 'g').test(to.path)) {
                titlePath.push(treeMenu.name);
            }
        }

        // 첫 번째 메뉴를 제거하여 제목 경로를 정리합니다.
        titlePath.splice(0, 1);

        // Vuex 스토어에 현재 보여지는 메뉴 경로 이름을 설정합니다.
        store.commit('app/SET_SHOW_MENU_PATH_NAME', titlePath.join(' > '));

        // 제목 경로를 역순으로 정렬하여 페이지 제목을 설정합니다.
        titlePath = titlePath.reverse();
        title = titlePath.join(' < ');

        // 인증된 경우 페이지 제목을 설정하고 다음 라우트로 이동합니다.
        if (authenticated) {
            document.title = title;
            next();
        } else {
            // 인증되지 않은 경우 루트('/') 경로로 리다이렉트합니다.
            next('/');
        }
    } else {
        // 사용자가 인증되지 않은 경우

        // 로그인 여부를 저장할 변수입니다.
        let isLogin = false;

        // 사용자 권한을 제거합니다.
        meta.auth.unauthorize();

        // 특정 경로에 따라 페이지 제목을 설정하고 라우팅을 처리합니다.
        if (to.path.startsWith('/sign-up')) {
            subTitle = '회원가입';
            titlePath = [subTitle, mainTitle];
            title = titlePath.join(' < ');
            document.title = title;
            next();
        } else if (to.path.startsWith('/sign-in')) {
            subTitle = '로그인';
            titlePath = [subTitle, mainTitle];
            title = titlePath.join(' < ');
            document.title = title;
            next();
        } else if (to.path.startsWith('/id-find')) {
            subTitle = '아이디 찾기';
            titlePath = [subTitle, mainTitle];
            title = titlePath.join(' < ');
            document.title = title;
            next();
        } else if (to.path.startsWith('/pw-find')) {
            subTitle = '비밀번호 찾기';
            titlePath = [subTitle, mainTitle];
            title = titlePath.join(' < ');
            document.title = title;
            next();
        } else {
            // 기본 메뉴 목록을 가져옵니다.
            treeMenuList = (await meta.api.util.menu.getDefaultMenuList()).data.items;

            // 제목 경로를 초기화합니다.
            titlePath = ['Dneuro'];

            // 루트 경로일 경우 '메인화면'을 추가합니다.
            if (to.path === '/') {
                titlePath.push('메인화면');
            }

            // 사용자가 접근 가능한 공개 메뉴를 확인합니다.
            for (i = 0; i < treeMenuList.length; i += 1) {
                treeMenu = treeMenuList[i];
                if (treeMenu.path === to.path) {
                    if (treeMenu.publicyStatus === 'F') {
                        // 접근이 제한된 경우 알림을 표시합니다.
                        meta.alert('로그인이 필요합니다.');
                        isLogin = true;
                    } else {
                        // 접근 가능한 경우 제목 경로에 추가합니다.
                        titlePath.push(treeMenu.name);
                    }
                }
            }

            // 로그인이 필요한 경우 로그인 페이지로 리다이렉트합니다.
            if (isLogin) {
                router.push({ path: '/sign-in', query: { redirectURL: to.fullPath } });
            } else if (titlePath.length === 1) {
                // 유효하지 않은 경로인 경우 루트로 리다이렉트합니다.
                router.push({ path: '/' });
            } else {
                // 메뉴 경로 이름을 설정하고 제목을 업데이트합니다.
                storeTemp.state.app.showMenuPathName = titlePath.join(' > ');
                titlePath = titlePath.reverse();
                title = titlePath.join(' < ');
                document.title = title;
                next();
            }
        }
    }
});

// VueRouter 인스턴스를 내보냅니다.
export default router;
